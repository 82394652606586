import React from 'react';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';



function Home() {
  return (
    <>

     {/* TEST AVEC L'IMAGE EN DESSOUS  */}


      <HeroSection />
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
